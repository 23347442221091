import 'isomorphic-fetch';

export interface Event {
  type: string;
  environment: string;
  organization: string;
  app: string;
  session: string;
  date: string; // YYYY-MM-DDThh:mm:ss.sssZ
  payload: Record<string, any>;
  metadata: Record<string, any>;
}

export default function sendEvent(event: Event) {
  const url =
    process.env.NODE_ENV === 'development'
      ? 'https://analytics-qa.omborigrid.com/api/track'
      : 'https://analytics.omborigrid.com/api/track';
  const data = JSON.stringify(event);

  if (typeof navigator !== 'undefined' && typeof navigator.sendBeacon === 'function') {
    return navigator.sendBeacon(url, data) ? Promise.resolve() : Promise.reject();
  }

  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'text/plain;charset=UTF-8' },
    body: data,
  }).then((res) => (res.ok ? Promise.resolve() : Promise.reject()));
}
