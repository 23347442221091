import React from 'react';
import useShortUrlQrCode from './use-short-url-qr-code';
import QrCode from 'qrcode.react';

interface Props {
  url: string;
  size: number;
  children?: (shortUrl: string) => any;
}

const ShortUrlQrCode = ({ url, children, size, ...props }: Props) => {
  const [shortUrl, qrCodeValue] = useShortUrlQrCode(url);

  return (
    <>
      {qrCodeValue && <QrCode value={qrCodeValue} size={size} {...props} />}
      {children && shortUrl ? children(shortUrl) : null}
    </>
  );
};

export default React.memo(ShortUrlQrCode);
