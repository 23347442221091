import { once } from 'lodash';

const CACHE = '_OMBORI_GDM_ANALYTICS_CACHE';

const supportsLocalStorage = once(() => {
  const value = '_OMBORI_GDM_ANALYTICS_LOCAL_STORAGE_TEST';

  try {
    localStorage.setItem(value, value);
    localStorage.removeItem(value);
    return true;
  } catch (e) {
    return false;
  }
});

export const cache: any[] = supportsLocalStorage()
  ? JSON.parse(localStorage.getItem(CACHE) || '[]')
  : [];

export const cacheEvent = (event: any) => {
  // If the device is offline for a long time we don't want the cache to grow infinitely
  if (cache.length >= 10000) {
    cache.shift();
  }

  cache.push({
    ...event,
    metadata: { ...event.metadata, cachedEvent: true },
  });

  supportsLocalStorage() && localStorage.setItem(CACHE, JSON.stringify(cache));
};

const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const flushCache = async (trackEvent: (event: any) => void) => {
  while (cache.length) {
    const event = cache.shift();
    if (event) {
      trackEvent(event);
      // Don't flush the cache too quickly as it can lead to dropped events
      await delay(100);
    }
  }

  supportsLocalStorage() && localStorage.removeItem(CACHE);

  flushCacheOnce = once(flushCache);
};

export let flushCacheOnce = once(flushCache);
