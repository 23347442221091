import { useState, useEffect } from 'react';
import 'abort-controller/polyfill';
import shorten from './shorten';
import log from './utilities/log';

const delay = (ms: number, signal: RequestInit['signal']) => {
  if (signal && signal.aborted) {
    return Promise.reject(new DOMException('Aborted', 'AbortError'));
  }

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(resolve, ms);

    if (signal) {
      signal.addEventListener('abort', () => {
        clearTimeout(timeout);
        reject(new DOMException('Aborted', 'AbortError'));
      });
    }
  });
};

export default function useShortUrlQrCode(url: string = ''): [string, string] {
  const [shortUrl, setShortUrl] = useState('');
  const [qrCodeValue, setQrCodeValue] = useState('');

  useEffect(() => {
    setShortUrl('');
    setQrCodeValue('');

    let controller: any;

    function generateUrlAndCode() {
      if (url) {
        controller = new AbortController();

        shorten(url, controller.signal)
          .then((nextShortUrl) => {
            controller.abort();
            setShortUrl(nextShortUrl.replace('https://', ''));
            setQrCodeValue(`${nextShortUrl}?scan=1`);
          })
          .catch((err) => {
            if (err.name !== 'AbortError') {
              log(err.message);
            }
          });

        delay(3000, controller.signal)
          .then(() => {
            controller.abort();
            setQrCodeValue(url);
          })
          .catch((err) => {
            if (err.name !== 'AbortError') {
              log(err.message);
            }
          });
      }
    }

    generateUrlAndCode();
    const qrRefreshInterval = setInterval(generateUrlAndCode, 1000 * 60 * 60 * 23);

    return () => {
      if (controller) controller.abort();
      if (qrRefreshInterval) clearInterval(qrRefreshInterval);
    };
  }, [url]);

  return [shortUrl, qrCodeValue];
}
