import { getInstance as getAnalyticsInstance } from '@ombori/gdm-analytics';

export default function shorten(url: string, signal?: RequestInit['signal']) {
  return fetch('https://qr.run/api/v1/shorten', {
    signal,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      url,
      analytics: getAnalyticsInstance(),
    }),
  })
    .then((res) => res.json())
    .then(({ shortUrl }) => shortUrl);
}
