import { useState, useEffect } from 'react';
import 'abort-controller/polyfill';
import shorten from './shorten';
import log from './utilities/log';

export default function useShortUrl(url: string = '') {
  const [shortUrl, setShortUrl] = useState('');

  useEffect(() => {
    setShortUrl('');

    let controller: any;

    function generateUrlAndCode() {
      if (url) {
        controller = new AbortController();

        shorten(url, controller.signal)
          .then((nextShortUrl) => setShortUrl(nextShortUrl))
          .catch((err) => {
            if (err.name !== 'AbortError') {
              log(err.message);
            }
          });
      }
    }

    generateUrlAndCode();
    const refreshUrlInterval = setInterval(generateUrlAndCode, 1000 * 60 * 60 * 23);

    return () => {
      if (controller) controller.abort();
      if (refreshUrlInterval) clearInterval(refreshUrlInterval);
    };
  }, [url]);

  return shortUrl;
}
